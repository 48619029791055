import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function PrivacyPolicy() {
    return (
        <Layout>
            <Seo title="Privacy Policy" />
            <section className="hero bg_img" data-background="/bg/hero.jpg">
                <div className="hero__shape">
                    <img src="/elements/hero/shape-2.png" alt="no surrender" />
                </div>
                <div className="el-1">
                    <img src="/elements/hero/el-1.png" alt="no surrender" />
                </div>
                <div className="el-2">
                    <img src="/elements/hero/el-2.png" alt="no surrender" />
                </div>
                <div className="el-3">
                    <img src="/elements/hero/el-3.png" alt="no surrender" />
                </div>
                <div className="el-4">
                    <img src="/elements/hero/el-4.png" alt="no surrender" />
                </div>
                <div className="el-5">
                    <img src="/elements/hero/el-5.png" alt="no surrender" />
                </div>
                <div className="el-6">
                    <img src="/elements/hero/el-6.png" alt="no surrender" />
                </div>
                <div className="el-7">
                    <img src="/elements/hero/el-7.png" alt="no surrender" />
                </div>
                <div className="el-8">
                    <img src="/elements/hero/el-8.png" alt="no surrender" />
                </div>
                <div className="el-9">
                    <img src="/elements/hero/el-9.png" alt="no surrender" />
                </div>
                <div className="el-10">
                    <img src="/elements/hero/el-10.png" alt="no surrender" />
                </div>
                <div className="el-11">
                    <img src="/elements/hero/el-11.png" alt="no surrender" />
                </div>
            </section>
            <section className="pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="hero__content d-flex flex-column align-items-center">
                                <h2
                                    className="hero__title wow fadeInUp text-center"
                                    data-wow-duration="0.5s"
                                    data-wow-delay="0.5s"
                                >
                                    PRIVACY POLICY
                                </h2>
                                <div>
                                    <p class="c3"><span class="c0">Effective Date: April 15, 2021</span></p>
                                    <p class="c3"><span class="c0">Thank you for playing our games! This Privacy Policy describes:</span></p>
                                    <ul class="c11 lst-kix_js0ee76cjw4c-0 start">
                                        <li class="c12 li-bullet-0"><span class="c0">The ways we collect personal data about you and why we do so</span>
                                        </li>
                                        <li class="c12 li-bullet-0"><span class="c0">How we use your personal data, and</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">The choices you have about your personal data.</span></li>
                                    </ul>
                                    <p class="c3"><span class="c0">This Privacy Policy applies to NoSurrender&#39;s games, store, sites and related
                                        services, which we here collectively call the Service. We may periodically update this Privacy Policy by
                                        posting a new version on nosurrender.studio. If we make any material changes, we will notify you as required
                                        under applicable law, including by posting a notice in the Service prior to the change becoming effective.
                                        Your continued use of the Service after the effective date will be subject to the new Privacy Policy.</span>
                                    </p>
                                    <h3 class="c4" id="h.6tm1vyffb1h"><span class="c14">CONTACT US</span></h3>
                                    <p class="c3"><span class="c0">If you have questions about data protection, or if you have any requests for
                                        resolving issues with your personal data, we encourage you to primarily contact us through the support
                                        features within each NoSurrender game, so we can reply to you more quickly. Alternatively, you may
                                        contact:</span></p>
                                    <p class="c3"><span class="c0">Name of the controller: NoSurrender Oy</span></p>
                                    <p class="c3"><span class="c0">Address: Sahkulu mh. Yazarlar sk No:27/5 Galata &#304;stanbul Turkey Attn:
                                        Privacy</span></p>
                                    <p class="c3"><span class="c0">email: community@nosurrender.studio</span></p>
                                    <h3 class="c4" id="h.s7qq6xkwp9m2"><span class="c14">THE DATA WE COLLECT</span></h3>
                                    <p class="c3"><span class="c0">The categories of personal data we collect depend on the Services you use, and the
                                        requirements of applicable law.</span></p>
                                    <h4 class="c15 c16" id="h.8jgx1ngzwdj5"><span class="c10">Data you provide us directly.</span></h4>
                                    <ul class="c11 lst-kix_lybmzaqq25xi-0 start">
                                        <li class="c12 li-bullet-0"><span class="c0">Contact information (such as name and email address or phone
                                            numbers)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Player name or tag and password</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Profile information (such as profile photo)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Your messages to the Service (such as chat logs and player support
                                            tickets)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Other data you choose to give us (such as data to identify a lost
                                            account or data you provide to participate in survey or promotional programs)</span></li>
                                    </ul>
                                    <h4 class="c15 c16" id="h.iyr5h6coob0s"><span class="c10">Data we collect automatically.</span></h4>
                                    <ul class="c11 lst-kix_3kc770ybshjs-0 start">
                                        <li class="c12 li-bullet-0"><span class="c0">Data about your account and game progress, including in most cases
                                            an automatically created internal account ID</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Your IP address and mobile device identifiers (such as your device
                                            or advertising ID)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Data about your device, such as device name and operating system,
                                            browser type and language, internet service provider, and mobile carrier</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Data we collect with cookies and similar technologies (see more
                                            below)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Approximate location data (as derived from IP address)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Data about your use of the Service, such as gameplay data,
                                            purchases made and your interactions with other players inside the Service</span></li>
                                    </ul>
                                    <p class="c3"><span class="c0">You also have the option to create a NoSurrender ID using your email address or other
                                        contact information.</span></p>
                                    <h4 class="c15 c16" id="h.3byoovay5fq1"><span class="c10">Data we collect from our partners.</span></h4>
                                    <p class="c3"><span class="c0">We also use third-party partners, such as social networking sites, data analytics
                                        providers and advertising networks to supplement information we have about you, such as:</span></p>
                                    <ul class="c11 lst-kix_a5c70sqbgvzo-0 start">
                                        <li class="c12 li-bullet-0"><span class="c0">Data we receive if you link a third-party tool with the Service
                                            (such as LINE, Facebook, WeChat or Kakao)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Demographic data (such as to determine the coarse location of your
                                            IP address)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Data to fight fraud (such as refund abuse in games or click fraud
                                            in advertising)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Data from platforms that the games run on or data from payment
                                            service providers (such as payment verification data)</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Data for advertising and analytics purposes (such as surveys), so
                                            we can provide you a better Service</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Data that you have made available to us from a third party service
                                            (such as LINE, Facebook, WeChat or Kakao) via your privacy settings</span></li>
                                    </ul>
                                    <h3 class="c4" id="h.xev0do2x435"><span class="c14">WHY DO WE COLLECT YOUR DATA</span></h3>
                                    <p class="c3"><span class="c0">We use your data for a variety of business purposes, such as:</span></p>
                                    <h4 class="c15 c16" id="h.nyp1tj73cy4l"><span class="c10">To make the Service work.</span></h4>
                                    <p class="c3"><span class="c0">To perform the contract, we process data necessary to</span></p>
                                    <ul class="c11 lst-kix_6tdvji63dis0-0 start">
                                        <li class="c12 li-bullet-0"><span class="c0">Create accounts and allow you to play our games and use our
                                            Service</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Operate the Service</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Verify and confirm payments</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Provide and deliver products and services you request</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Send you Service-related communications</span></li>
                                    </ul>
                                    <h4 class="c15 c16" id="h.ognke74ysrtw"><span class="c10">To make the Service more suitable for our players.</span>
                                    </h4>
                                    <p class="c3"><span class="c0">To provide a great Service to our players, we have a legitimate interest to collect
                                        and process necessary data to</span></p>
                                    <ul class="c11 lst-kix_epa1cosyo8ru-0 start">
                                        <li class="c12 li-bullet-0"><span class="c0">Update and develop player profiles</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Develop and improve the Service and player experience</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Manage our relationship with you</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Provide social features as part of the Service</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Customize your Service experience</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Respond to your comments and questions and provide player
                                            support</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Provide you NoSurrender offers in the Service as well as in other
                                            websites and services, and by email</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Send you related information, such as updates, security alerts, and
                                            support messages</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Enable you to communicate with other players</span></li>
                                    </ul>
                                    <h4 class="c15 c16" id="h.o1z3es9vamhk"><span class="c10">To show personalized advertisements.</span></h4>
                                    <p class="c3"><span class="c0">To show you personalized advertisements in the Service as well as in other websites
                                        and services (including email) we have a legitimate interest to process necessary data to</span></p>
                                    <ul class="c11 lst-kix_8r186l5xzvxx-0 start">
                                        <li class="c12 li-bullet-0"><span class="c0">Track the content you access in connection with the Service and
                                            your online behavior</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Deliver, target and improve our advertising and the Service</span>
                                        </li>
                                    </ul>
                                    <p class="c3"><span class="c0">For information on how to opt-out from personalized advertisements, see section
                                        &#39;Your Rights and Options&#39; below.</span></p>
                                    <h4 class="c15 c16" id="h.fe9itgrnevla"><span class="c10">To keep the Service safe and fair.</span></h4>
                                    <p class="c3"><span class="c19">Ensuring a level playing field in the Service is a top priority for us. For more
                                        information on our acceptable use policy, see the </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/terms-of-service" target="_blank">NoSurrender
                                            Terms of Service</a></span><span class="c0">.</span></p>
                                    <p class="c3"><span class="c0">In order to keep the Service and its social features safe and fair, to fight fraud
                                        and ensure acceptable use otherwise, we have a legitimate interest to process necessary data to</span></p>
                                    <ul class="c11 lst-kix_8ao5g55j1tqn-0 start">
                                        <li class="c12 li-bullet-0"><span class="c0">Analyze and monitor use of the Service and its social
                                            features</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Moderate chats either automatically or manually</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Take action against fraudulent or misbehaving players</span></li>
                                    </ul>
                                    <h4 class="c15 c16" id="h.3jyfo4in0cil"><span class="c10">To analyze, profile, and segment.</span></h4>
                                    <p class="c3"><span class="c0">In all of the above cases and purposes, we may analyze, profile and segment all
                                        collected data.</span></p>
                                    <h4 class="c15 c16" id="h.sj096hfon1b1"><span class="c10">With your consent.</span></h4>
                                    <p class="c3"><span class="c0">With your consent, we may process your data for additional purposes.</span></p>
                                    <h3 class="c4" id="h.w0dk6pj8oxh1"><span class="c14">WHO CAN SEE YOUR DATA</span></h3>
                                    <p class="c3"><span class="c0">Apart from NoSurrender, your data can be accessed by others in the following
                                        situations:</span></p>
                                    <h4 class="c15 c16" id="h.fypqusuxy1jj"><span class="c10">Other players and users.</span></h4>
                                    <p class="c3"><span class="c0">Social features are a core component of our games. Other players and users may, for
                                        example, see your profile data, in-game activities and read the messages you have posted.</span></p>
                                    <h4 class="c15 c16" id="h.r61d1gg7qo01"><span class="c10">Our service providers.</span></h4>
                                    <p class="c3"><span class="c0">NoSurrender has vendors who help us to provide the Service. These vendors process
                                        your data only at and according to NoSurrender&acute;s instructions to provide the Service, and perform
                                        tasks such as hosting, player support, advertising, analytics and fraud prevention.</span></p>
                                    <h4 class="c15 c16" id="h.nrytrr9akxkn"><span class="c10">Other companies and public authorities.</span></h4>
                                    <p class="c3"><span class="c0">In order to verify payments (with payment providers such as Paypal) and combat fraud
                                        and illegal activity, we may process and disclose data with other companies and organizations and provide it
                                        to public authorities in response to lawful requests.</span></p>
                                    <p class="c3"><span class="c0">We may also disclose your data based on your consent, to comply with the law or to
                                        protect the rights, property or safety of us, our players or others.</span></p>
                                    <h4 class="c15 c16" id="h.4cwfrxmpldat"><span class="c10">Advertising and Social Media partners.</span></h4>
                                    <p class="c3"><span class="c19">The Service includes features from our partners, such as social media interaction
                                        tools, functionalities through application programming interfaces (APIs) or software development kits (SDKs)
                                        and in-game advertising. A list of these partners is available at </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/partner-opt-out" target="_blank">https://nosurrender.studio/partner-opt-out</a></span><span
                                                class="c0">. These partners may access your data and operate under their own privacy policies. We encourage
                                            you to check their privacy policies to learn more about their data processing practices.</span></p>
                                    <p class="c3"><span class="c0">These partners may access data regarding your activities and your device (such as
                                        your IP address, mobile identifiers, page(s) visited, location, time of day). We may also combine and share
                                        data we have collected about you with third-party advertising partners. These advertising partners may use
                                        this data (and similar data collected from other websites) for purposes of delivering targeted
                                        advertisements to you when you visit third-party services within their networks. These partners may operate
                                        under their own privacy policies. This practice is commonly referred to as &ldquo;interest-based
                                        advertising&rdquo; or &ldquo;online behavioral advertising.&rdquo; If you prefer not to share your personal
                                        data with third-party advertising partners, you may follow the instructions in &ldquo;Your Rights and
                                        Options&rdquo; below.</span></p>
                                    <h3 class="c4" id="h.oxeyhwculiqp"><span class="c14">INTERNATIONAL DATA TRANSFERS</span></h3>
                                    <p class="c3"><span class="c0">Our Service is global by nature and your data can therefore be transferred to
                                        anywhere in the world. Because different countries may have different data protection laws than your own
                                        country, we take steps to ensure adequate safeguards are in place to protect your data as explained in this
                                        Policy. Adequate safeguards that we may use include standard contractual clauses approved by EU Commission
                                        and other lawful safeguards.</span></p>
                                    <h3 class="c4" id="h.1huaxa858o3m"><span class="c14">YOUR RIGHTS AND OPTIONS</span></h3>
                                    <h4 class="c15 c16" id="h.rmb00sygftrf"><span class="c10">Opt-out of marketing emails and other direct
                                        marketing.</span></h4>
                                    <p class="c3"><span class="c19">You may opt-out of receiving promotional communications, such as marketing emails
                                        from us by following the instructions in such communications, or by changing your in-game settings. The
                                        updated settings may not be effective immediately. Note that you may still continue to receive
                                        non-promotional communications from us, such as communications regarding the Service or updates to our
                                    </span><span class="c19"><a class="c1"
                                        href="https://nosurrender.studio/terms-of-service" target="_blank">Terms
                                        of Service</a></span><span class="c0">&nbsp;or this Privacy Policy.</span></p>
                                    <h4 class="c15 c16" id="h.5mg3eklthtml"><span class="c10">Push Notifications</span></h4>
                                    <p class="c3"><span class="c0">We may send you push notifications through our mobile applications. You may at any
                                        time opt-out from receiving these types of communications by changing the settings on your mobile
                                        device.</span></p>
                                    <h4 class="c15 c16" id="h.i26zh8ccc9pz"><span class="c10">Opt-out of targeted advertising.</span></h4>
                                    <p class="c3"><span class="c19">You can opt-out of interest-based advertising on mobile applications by checking the
                                        privacy settings of your Android or iOS device and turning off &ldquo;Allow Apps to Request to Track&rdquo;
                                        or selecting &quot;Limit Ad Tracking&quot; (Apple iOS) or &quot;Opt-out of Interest Based Ads&quot;
                                        (Android). For more information, see also: </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/partner-opt-out" target="_blank">https://nosurrender.studio/partner-opt-out</a></span><span
                                                class="c0">.</span></p>
                                    <p class="c3"><span class="c0">You can also opt out from personalized in-game offers through the options provided in
                                        the game settings (where applicable).</span></p>
                                    <h4 class="c15 c16" id="h.9z3eeulbphis"><span class="c10">Access the personal data we hold about you.</span></h4>
                                    <p class="c3"><span class="c0">If you request, we will provide you a copy of your personal data in an electronic
                                        format.</span></p>
                                    <h4 class="c15 c16" id="h.72p41zba4pzc"><span class="c10">Your other rights.</span></h4>
                                    <p class="c3"><span class="c0">You also have the right to correct your data, have your data deleted, object how we
                                        use or share your data, and restrict how we use or share your data. You can always withdraw your
                                        consent.</span></p>
                                    <p class="c3"><span class="c0">We will respond to all requests within a reasonable timeframe. &nbsp;If you have an
                                        unresolved privacy or data use concern that we have not addressed satisfactorily, you may also contact your
                                        local data protection authority within the European Economic Area, Switzerland or UK for unresolved
                                        complaints.</span></p>
                                    <p class="c3"><span class="c0">If you wish to exercise any of your data subject rights described above, please use
                                        the support options described in &ldquo;Contact Us&rdquo; above. We will respond to all requests in
                                        accordance with applicable laws. To protect your privacy, we may also take additional steps to verify your
                                        identity before fulfilling your request, such as by requesting you provide us a purchase receipt or other
                                        account information, asking you to link your email address with your game account (for example through
                                        NoSurrender ID), or asking you to answer questions regarding your activities on our Services. Once you have
                                        verified your identity, you may also designate an authorized agent to exercise your rights on your behalf by
                                        providing the agent&rsquo;s contact information to our customer support staff, as required by applicable
                                        law.</span></p>
                                    <p class="c3"><span class="c0">You have the right not to receive discriminatory treatment as a result of your
                                        exercise of these rights.</span></p>
                                    <h3 class="c4" id="h.rcn0y5uwctgy"><span class="c14">COOKIES AND SIMILAR TECHNOLOGIES</span></h3>
                                    <p class="c3"><span class="c0">Like most online services, we and our partners use cookies and similar technologies
                                        to provide and personalize the Service, analyse use, target advertisements and prevent fraud. Cookies and
                                        similar technologies allow us and our partners to store their preferences and track your activities within
                                        the Service. Note that our partners may operate under their own privacy policies.</span></p>
                                    <p class="c3"><span class="c0">We and our partners collect and store information about users&#39; interactions with
                                        unaffiliated websites and applications that use our technologies, including cookies and similar tracking
                                        technologies. This allows us to infer the presence of a common user or household behind multiple devices or
                                        browsers, for instance, and then link those browsers and devices into a device graph. We do so in order
                                        to</span></p>
                                    <ul class="c11 lst-kix_s90a2k6pqg4l-0 start">
                                        <li class="c12 li-bullet-0"><span class="c0">detect and prevent fraud;</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">allow users to use the Service on one device and pick up seamlessly
                                            where they left off on another device;</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">analytics, personalization and attribution;</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">limit the number of times a user is shown the same advertisement,
                                            across all known or inferred devices; and/or</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">provide personalized advertising on each device that is inferred
                                            from the browsing patterns on all of the devices.</span></li>
                                    </ul>
                                    <p class="c3"><span class="c19">For more information (including how to opt out), please visit </span><span
                                        class="c19"><a class="c1"
                                            href="https://nosurrender.studio/partner-opt-out" target="_blank">https://nosurrender.studio/partner-opt-out</a></span><span
                                                class="c0">&nbsp;You can disable cookies in your browser settings, but some parts of the Service may then
                                            not function properly.</span></p>
                                    <h3 class="c4" id="h.l7unp7qqpye2"><span class="c14">HOW DO WE PROTECT YOUR DATA</span></h3>
                                    <h4 class="c15 c16" id="h.vabus2adaivq"><span class="c10">Security Safeguards.</span></h4>
                                    <p class="c3"><span class="c0">In order to help ensure a secure and safe player experience, we are continuously
                                        developing and implementing administrative, technical and physical security measures to protect your data
                                        from unauthorized access or against loss, misuse or alteration.</span></p>
                                    <h4 class="c15 c16" id="h.7wwz3tlxvmhf"><span class="c10">Data retention.</span></h4>
                                    <p class="c3"><span class="c0">We retain your data for as long as your account is active or as needed to provide you
                                        the Service. We may for example periodically de-identify unused game accounts, and we may regularly review
                                        and de-identify unnecessary data.</span></p>
                                    <p class="c3"><span class="c0">Note that if you ask us to remove your personal data, we will retain your data as
                                        necessary for our legitimate business interests, such as to comply with our legal obligations, resolve
                                        disputes, and enforce our agreements.</span></p>
                                    <h3 class="c4" id="h.7i0s6yjlfvbu"><span class="c14">CHILDREN</span></h3>
                                    <p class="c3"><span class="c0">Protecting children&rsquo;s privacy online is very important to us. Generally, our
                                        Services are not directed to children under 13 (or other age as required by local law), and the Services do
                                        not knowingly collect personal data from children, except as described in the following paragraph.</span>
                                    </p>
                                    <p class="c3"><span class="c0">For games that are not directed at children but nonetheless may appeal to them, we
                                        take additional steps to limit access to these Services based on the player&rsquo;s age. For these Services,
                                        when a player indicates they are under 13 (or the applicable age in their territory), we will limit their
                                        access to certain features, such as social media and chat features, as well as certain types of notification
                                        alerts. We also limit the processing of personal data to only what is necessary to support the internal
                                        operations of our Services.</span></p>
                                    <p class="c3"><span class="c0">Please note that for some of our Services we may use a higher age limit (such as 16)
                                        than described in the above two paragraphs. We do this to provide a safe game experience for our players and
                                        to protect Young Players.</span></p>
                                    <p class="c3"><span class="c0">If you are a parent or guardian and wish to review personal data collected from your
                                        child, or have that information modified or deleted, you may contact us as described above. If we become
                                        aware that a child has provided us with personal data contrary to the above, we will delete any personal
                                        data we have collected, unless we have a legal obligation to keep it, and terminate the child&rsquo;s
                                        account and/or revert them to the underage experience, as applicable.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PrivacyPolicy
